
import pic_0  from '../../img//slide_0.jpg';
import pic_1  from '../../img//slide_1.jpg';

function About() {
  return (
    <>
     <div className='content-wrapper'>
          <h1>About</h1>
          <div className='row-box'>
          
          <div className='col-2'>
          
                      <p><span className='bold-text'>MIOLO</span> is derived from the TshiVenda language, meaning Art. We simply
  believe that all we do and all we are inspired by is essentially… art. Give
  us your most way out ideas and let us use our artistic core to make your
  ideas work for you in the best way.</p>
  <p><span className='bold-text'>COMMUNICATE</span> the senders’ message <br/>
  <span className='bold-text'>INSPIRE</span> the receivers to accept the message<br/>
  <span className='bold-text'>RESIDE</span> in the hearts and minds of both.</p>
            <p align='right'> </p>
            </div>
          
            <div className='col-2'>
              <p></p>
            <div className='row-label'>
            <div className='letter-label'>
                A</div>
                <div className='desc'><span className='bold-text'>APTITUDE</span> to serve clients; creative needs
                </div>
                </div>
                <div className='row-label'>
                <div className='letter-label'>
                R</div>
                <div className='desc'><span className='bold-text'>REALISATION</span> of clients’ intentions
                </div>
                </div>
                <div className='row-label' style={{borderBottom : '1px solid #ccc' }}>
                <div className='letter-label'>
                T</div>
                <div className='desc'><span className='bold-text'>TENACITY</span>  to meet clients’ objectives
                </div>
                </div>
           
            </div>
          </div><div className='image-art' style={{backgroundImage: `url(${pic_0})` , backgroundSize : 'cover'}}></div>
          <div className='row-box'>
          
          <div className='col-2'>
          <h2>Sectors</h2>
                      <p>We develop programmes based on our understanding of a sector’s dynamics in both the B2B and B2C
environments. Our goal is to work with our clients collaboratively to elevate their market place standing.
</p><p> <span className='bold-text'>The main sectors include:</span>
<ul>
<li>Business and Finance</li>
<li>Retail</li>
<li>Consumer</li>
<li>Pharmaceutical</li>
<li>Business and Finance</li>
<li>Technology</li>
<li>Government (Local, National Departments & Municipalities)</li>
<li>Digital</li>
<li>Entertainment</li>
</ul></p>
            <p align='right'> </p>
            </div>
          
            <div className='col-2'>
              <p></p>
            <div className='row-label'>
            <div className='letter-label'>
                A</div>
                <div className='desc'><span className='bold-text'>APTITUDE</span> to serve clients; creative needs
                </div>
                </div>
                <div className='row-label'>
                <div className='letter-label'>
                R</div>
                <div className='desc'><span className='bold-text'>REALISATION</span> of clients’ intentions
                </div>
                </div>
                <div className='row-label' style={{borderBottom : '1px solid #ccc'}}>
                <div className='letter-label'>
                T</div>
                <div className='desc'><span className='bold-text'>TENACITY</span>  to meet clients’ objectives
                </div>
                </div>
           
            </div>
          </div><div className='image-art' style={{backgroundImage: `url(${pic_1})` , backgroundSize : 'cover'}}></div>
          <div className='spacer'></div>
        </div>
  </>
  );
}

export default About;
