
import Dropdown from './Dropdown';
import PopupMenu from './PopupMenu';


function Header() {
  return (
<>
<PopupMenu />
    <div className='header'>
    <div className='header-wrapper '>
      <div className='logo'></div>
      <div className='menu'>
        <a href='/'>home</a>
        <a href='/about'>about</a>
        <a href='/services'>services</a>
        <a href='/clients'>clients</a>
        <a href='/contact'>contact us</a>
     
</div>


</div>
      </div>
      </>);
}

export default Header;
