import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

//import App from './App';
import reportWebVitals from './reportWebVitals';

import './css/index.css';
//import './css/slick.css';
//import './css/slick-theme.css';

import Layout from './pages/public/Layout';
import Home from './pages/public/Home';
import About from './pages/public/About';
import Services from './pages/public/Services';
import Clients from './pages/public/Clients';
import Partners from './pages/public/Partners';
import Contact from './pages/public/Contact';

import NoPage from './pages/public/NoPage';

import Animation from './pages/public/Animation';
import Comicbooks from './pages/public/Comicbooks';
import Games from './pages/public/Games';


function App() {
  return (
    <BrowserRouter basename="/">
      <Routes>
          <Route path="/" element={<Layout />}>
            
              <Route index element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="services" element={<Services />} />
              <Route path="clients" element={<Clients />} />
              <Route path="partners" element={<Partners />} />
              <Route path="contact" element={<Contact />} />
              <Route path="*" element={<NoPage />} />

        </Route>
        <Route path="/entertainment" element={<Layout />}>
            
            <Route index element={<Animation />} />
            <Route path="animation" element={<Animation />} />
            <Route path="comicbooks" element={<Comicbooks />} />
            <Route path="games" element={<Games />} />
            <Route path="*" element={<NoPage />} />

      </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
