


function Footer() {
  return (
      <div className='footer'>
      <div className='content-wrapper '>
        <div className='row-box'>
        
        <div className='col-3' style={{textAlign: 'right'}}>
          <h4>Site Map</h4>
          <ul>
          <li><a href='/'>Home</a></li>
          <li><a href='/about'>About us</a></li>
          <li><a href='/services'>services</a></li>
          <li><a href='/contact'>Contact us</a></li>
        
          </ul>
          </div>
        
          <div className='col-3'  style={{textAlign: 'center'}}>
          <h4>Copywrite</h4>
          © Miolo Studios 2023
        
          </div>
          <div className='col-3'>
          <h4>Co. Business Card</h4>
          info@miolostudios.co.za<br/>
livhu@miolostudios.co.za<br/>
+27 72 338 2377<br/>
www.miolostudios.co.za<br/>
Unit 4 b2 Cresent East<br/>
3 Eglin Road, Sunninghill<br/>
Johannesburg<br/>
          </div>
      
          </div>
          
    </div>
      
    </div>

  );
}

export default Footer;
