


function PopupMenu() {
  return (
    <div className='side-popup '>
    <div className='side-menu '>
      <div className='brand-box'>Brand box</div>
        
        <div className='menu'>
        <ul>
            <li><a>Home</a></li>
            <li><a>About</a></li>
            <li><a>Services</a></li>
            <li><a>Contact us</a></li>
        </ul>
          </div>
          </div></div>
  
  );
}

export default PopupMenu;
