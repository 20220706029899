


function About() {
  return (
    <>
      <div className='content-wrapper '>
        <h1>Games</h1>     
        <div className='row-box'>
        
        <div className='col-2'>
          <h4>Housing & Healthcare projects </h4>
          <p>•	Completed 300 RDP houses project (Fouriesburg) – R16,43m</p>
          <p>•	Completed Bohlokong Tennis Court and Clubhouse (Bethlehem) – R600 000.00 </p> 
          <p>•	Construction of Toilet Blocks ( MIRI and MAKGABANE Primary School) – R 1m</p>
          <p>•	Completion of Seotlong Agricultural School ( Major Renovations, Mechanical and Electricity) – R4 742 890.00 </p>
          <p>•	Completion of Thiboloha Special School ( Renovations and water reticulation) – R3 382 604.00</p>
</div>
        
          <div className='col-2'>
          
          <p>•	Completion of Carletornville Hospital (Major renovations)Dep of Infrastructure Development Gauteng – R1 600.000.00</p>
          <p>•	Vinyl covering, ceramic, walling brickwork (Helen Joseph Hospital) – R1 995 870.00</p>
<p>•	Subcontracting Paul Roux N5 National Road (Gabions)</p>
<p>•	Sub - Contracting Clarens Community Clinic</p>
          </div>
        </div>
        <div className='spacer'></div>
      </div>
  </>
  );
}

export default About;
