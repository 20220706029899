import React from "react";
import Slider from "react-slick";

import slider_1 from '../../img/slides/slide_1.jpg'
import slider_2 from '../../img/slides/slide_2.jpg'
import slider_3 from '../../img/slides/slide_3.jpg'
import slider_4 from '../../img/slides/slide_4.jpg'

export default function SimpleSlider() {
  var settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    innerHeight: 300,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
            
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        
          }
        }
      ]
  };
  return (
    <Slider {...settings}>
      <div><p className="slide-text-left">
     <div className='left-text-content'>
         <h1>EVENTS</h1>
         </div> </p>
        <img src={slider_1} />
      </div>
      <div><p className="slide-text-left"> 
      <div className='left-text-content'>
         <h1>Package Design</h1>
         </div> </p>
      <img src={slider_2} />     </div>
      <div>
      <p className="slide-text-left">
     <div className='left-text-content'>
         <h1>Business Vision</h1>
         <p>To be the preferred construction, property developers, property facilities management, and road maintenance contractor for the government, public and private markets.</p></div> </p>
        <img src={slider_3} />
      </div>
      <div>
      <p className="slide-text-right">
     <div className='right-text-content'>
         <h1>Business Vision</h1>
         <p>To be the preferred construction, property developers, property facilities management, and road maintenance contractor for the government, public and private markets.</p></div> </p>
        <img src={slider_4} />
      </div>
    </Slider>
  );
}