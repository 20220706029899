

import Slider from './Slider';

function Home() {
  return (
    <>
      <Slider />
      <div className='content-wrapper'>
          
        <div className='row-box'>
        
        <div className='col-2'>
        
                    <p><span className='bold-text'>MIOLO</span> is derived from the TshiVenda language, meaning Art. We simply
believe that all we do and all we are inspired by is essentially… art. Give
us your most way out ideas and let us use our artistic core to make your
ideas work for you in the best way.</p>
<p><span className='bold-text'>COMMUNICATE</span> the senders’ message <br/>
<span className='bold-text'>INSPIRE</span> the receivers to accept the message<br/>
<span className='bold-text'>RESIDE</span> in the hearts and minds of both.</p>
          <p align='right'> </p>
          </div>
        
          <div className='col-2'>
            <p></p>
          <div className='row-label'>
          <div className='letter-label'>
              A</div>
              <div className='desc'><span className='bold-text'>APTITUDE</span> to serve clients; creative needs
              </div>
              </div>
              <div className='row-label'>
              <div className='letter-label'>
              R</div>
              <div className='desc'><span className='bold-text'>REALISATION</span> of clients’ intentions
              </div>
              </div>
              <div className='row-label' style={{borderBottom : '1px solid #ccc'}}>
              <div className='letter-label'>
              T</div>
              <div className='desc'><span className='bold-text'>TENACITY</span>  to meet clients’ objectives
              </div>
              </div>
         
          </div>
        </div>
        <div className='spacer'></div>
      </div>
  </>
  );
}

export default Home;
